<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>了解威有</el-breadcrumb-item>
                <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>岗位列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="">
                <!-- <button @click="openOne()">添加一级</button> -->
                <VyAddBtn @click.native="openOne()"></VyAddBtn>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border row-key="id" @row-click="handleRowClick" ref="tab">
                <el-table-column prop="title" align="center" label="岗位名称"> </el-table-column>
                <el-table-column prop="status" align="center" label="招聘状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_add" v-if="row.pid == 0">
                                <button @click="openTwo(row)">添加子分类</button>
                            </div>
                            <div class="_operate">
                                <button @click="row.pid == 0 ? openOne(row) : openTwo(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑一级分类弹框 -->
        <el-dialog
            :title="`${form_one.id ? '编辑' : '添加'}一级分类`"
            :visible.sync="dialogShow_one"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_one" label-width="80px">
                <el-form-item label="岗位名称">
                    <el-input v-model.trim="form_one.title"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <uploadImgVue :src="form_one.head_image" @removeSrc="form_one.head_image = ''" ref="img"></uploadImgVue>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form_one.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="开启"
                        inactive-text="关闭"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_one = false">取 消</el-button>
                <el-button type="primary" @click="yes_one">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加或编辑二级分类弹框 -->
        <el-dialog
            :title="`${form_two.id ? '编辑' : '添加'}二级分类`"
            :visible.sync="dialogShow_two"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_two" label-width="80px">
                <el-form-item label="岗位名称">
                    <el-input v-model.trim="form_two.title"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form_two.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="开启"
                        inactive-text="关闭"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="富文本">
                    <editor
                        :txt="form_two.details"
                        :getTxt="
                            txt => {
                                form_two.details = txt;
                            }
                        "
                    ></editor>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_two = false">取 消</el-button>
                <el-button type="primary" @click="yes_two">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImgVue from "../../customs/uploadImg.vue";
import QueryString from "qs";
import editor from "../../customs/editor.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    name: "vyTalents",
    components: { uploadImgVue, editor, VyAddBtn },
    data() {
        return {
            tableData: [],
            form_one: {},
            dialogShow_one: false,
            form_two: {},
            dialogShow_two: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },

    methods: {
        async getData() {
            let { data } = await axios.get("/api/recruit/show");
            return data;
        },
        openOne(row) {
            if (row) {
                let { id, title, head_image, status } = row;
                this.form_one = { id, title, head_image, status };
            } else {
                this.form_one = {
                    title: "",
                    head_image: "",
                    status: 1,
                };
            }
            this.dialogShow_one = true;
        },
        async yes_one() {
            let { form_one: f } = this;
            f.head_image = this.$refs.img.url;
            if (f.id) {
                // 编辑
                var { data } = await axios.put("/api/recruit/fupdate", QueryString.stringify(f));
            } else {
                // 添加
                var { data } = await axios.post("/api/recruit/fadd", QueryString.stringify(f));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_one = false;
            this.tableData = await this.getData();
        },
        openTwo(row) {
            if (row.pid == 0) {
                // 添加
                this.form_two = {
                    title: "",
                    status: 1,
                    details: "",
                    pid: row.id,
                };
            } else {
                // 编辑
                let { id, title, status, details } = row;
                this.form_two = { id, title, status, details };
            }
            this.dialogShow_two = true;
        },
        async yes_two() {
            let { form_two: f } = this;
            if (f.id) {
                // 编辑
                var { data } = await axios.put("/api/recruit/update", QueryString.stringify(f));
            } else {
                // 添加
                var { data } = await axios.post("/api/recruit/add", QueryString.stringify(f));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_two = false;
            this.tableData = await this.getData();
        },
        // 删除
        async del({ id, pid }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            if (pid == 0) {
                // 删除一级
                var { data } = await axios.delete("/api/recruit/fdel", { params: { id } });
            } else {
                // 删除二级
                var { data } = await axios.delete("/api/recruit/del", { params: { id } });
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
